import React from "react";
import LoginComponent from "../../components/admin/login.component";
function LoginContainer() {
  return (
    <div>
      <LoginComponent />
    </div>
  );
}

export default LoginContainer;
