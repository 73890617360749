import React from "react";
import styled from "styled-components";
function Personal_information(props) {
  return (
    <PersonalSection>
      <h3>개인정보 처리방침</h3>
      <h5>1. 개인정보 처리의 목적</h5>
      <p>⦁ 회사는 경매플랫폼 서비스를 제공하기 위해 다음과 같은 목적으로 개인정보를 처리합니다:</p>
      <h6>
        ⦁ 회원 가입 및 관리: 회원 가입 절차, 회원 계정 관리, 본인 확인, 회원 탈퇴 처리 등을 위해 개인정보를 처리합니다.
      </h6>
      <h6>
        ⦁ 경매 진행 및 거래 관리: 경매 참여, 낙찰 처리, 거래 관리, 결제 처리, 배송 및 반품 처리 등을 위해 개인정보를
        처리합니다.
      </h6>
      <h6>
        ⦁ 서비스 제공 및 개선: 서비스 제공, 사용자 경험 개선, 서비스 안내 및 공지사항 전달, 이벤트 및 마케팅 활동 등을
        위해 개인정보를 처리합니다.
      </h6>
      <h6>
        ⦁ 법적 및 보안 요건 이행: 관련 법령 및 규정 준수, 분쟁 조정 및 해결, 보안 및 기술적인 문제 해결 등을 위해
        개인정보를 처리합니다.
      </h6>
      <h5>2. 수집하는 개인정보 항목</h5>
      <p>⦁ 회사는 다음과 같은 개인정보 항목을 수집할 수 있습니다:</p>
      <h6>⦁ 회원 가입 및 관리: 성명, 연락처, 이메일 주소, 계정 정보(아이디, 비밀번호 등) 등</h6>
      <h6>⦁ 경매 진행 및 거래 관리: 경매 참여 정보, 낙찰 정보, 결제 정보, 배송 정보 등</h6>
      <h6>⦁ 서비스 제공 및 개선: 서비스 이용 기록, 서비스 설정 정보, 서비스 이용에 대한 통계 등</h6>
      <h6>
        ⦁ 기타 필요한 정보: 법령에서 요구하는 경우, 이용자의 동의를 받은 경우, 기타 개인정보 수집을 필요로 하는 경우에
        한해 해당 정보를 수집할 수 있습니다.
      </h6>
      <h5>3. 개인정보의 보유 및 이용 기간</h5>
      <p>
        ⦁ 회사는 개인정보의 보유 및 이용 기간을 목적 달성 시까지 또는 이용자로부터 동의를 받은 기간까지로 제한합니다.
        단, 관련 법령에 따라 보존할 필요가 있는 경우에는 해당 기간에 따라 개인정보를 보관합니다.
      </p>
      <h5>4. 개인정보의 제공 및 위탁</h5>
      <p>
        ⦁ 회사는 이용자의 개인정보를 제3자에게 제공하는 경우, 관련 법령에 따라 필요한 동의를 얻거나, 법적인 의무를
        준수하며 안전한 개인정보 처리를 위해 필요한 조치를 취합니다.
      </p>
      <p>
        ⦁ 개인정보 처리를 위탁하는 경우, 회사는 안전한 처리를 위해 신뢰할 수 있는 외부 업체를 선정하고 계약 관계를 통해
        필요한 사항을 규정합니다.
      </p>
      <h5>5. 개인정보의 보호와 안전성</h5>
      <p>
        ⦁ 회사는 개인정보 보호를 위해 적절한 보안 조치를 취합니다. 개인정보 처리 시 개인정보의 분실, 도난, 누출, 변조,
        훼손 등을 방지하기 위한 기술적, 물리적, 관리적 조치를 적용합니다.
      </p>
      <p>⦁ 개인정보 처리에 관련된 직원에 대한 교육과 감독을 실시하여 개인정보 보호 의무를 준수하도록 합니다.</p>
      <p>
        ⦁ 개인정보 처리에 대한 접근 제한, 접근 기록의 보관, 시스템 및 네트워크 보안 등을 통해 안전한 개인정보 처리를
        유지합니다.
      </p>
      <h5>6. 이용자의 권리와 의무</h5>
      <p>
        ⦁ 이용자는 개인정보에 대한 열람, 정정, 삭제, 처리정지 요구 등의 권리를 행사할 수 있습니다. 해당 요청은 회사의
        개인정보 관리 담당자에게 제출하여야 합니다.
      </p>
      <p>
        ⦁ 이용자는 개인정보 제공에 동의하지 않을 권리가 있으며, 동의를 거부할 경우 일부 서비스 이용이 제한될 수
        있습니다.
      </p>
      <p>⦁ 이용자는 개인정보의 정확성을 유지하고 최신 정보를 제공해야 합니다.</p>
      <h5>7. 링크된 사이트</h5>
      <p>
        ⦁ 회사의 서비스에서는 다른 웹사이트에 대한 링크가 포함될 수 있습니다. 링크된 사이트에서는 회사의 개인정보
        처리방침이 적용되지 않으며, 해당 사이트의 개인정보 처리에 대해서는 해당 사이트의 개인정보 처리방침을 확인하여야
        합니다.
      </p>
      <h5>8. 개인정보 처리방침의 변경</h5>
      <p>
        ⦁ 회사는 개인정보 처리방침을 개정할 수 있습니다. 개인정보 처리방침의 변경이 있는 경우, 변경된 내용과 시행일을
        명시하여 회사의 웹사이트 또는 이메일 등의 방식으로 공지하고, 변경된 개인정보 처리방침은 시행일로부터 적용됩니다.
        개인정보 처리방침 변경 전에 이용자의 동의를 얻어야 하는 경우, 필요한 절차를 수행합니다.
      </p>
      <h5>9. 개인정보 보호 관련 문의처</h5>
      <p>
        ⦁ 개인정보 처리에 관련된 문의나 불만사항은 회사의 개인정보 관리 담당자에게 문의하여야 합니다. 회사는 이용자의
        문의에 대해 신속하고 성실하게 답변 및 처리할 것입니다.
      </p>
      <h5>10. 준거법 및 관할법원</h5>
      <p>
        ⦁ 본 개인정보 처리방침은 대한민국 법령에 따라 해석되고, 규율되며, 이에 따른 분쟁에 대해서는 대한민국의 관할
        법원을 합의 관할 법원으로 합니다. 위의 예시는 경매플랫폼과 관련된 개인정보 처리방침의 기본적인 내용을 포함하고
        있습니다. 필요에 따라 조항을 수정하거나 추가하여 사용하십시오. 또한, 개인정보 보호 관련 법령 및 규정을 준수하고,
        개인정보 처리에 대한 검토를 법률 전문가에게 의뢰하는 것이 좋습니다.
      </p>
    </PersonalSection>
  );
}

export default Personal_information;

const PersonalSection = styled.div`
  margin: 20px;
  padding: 10px;
  font-family: "NotoSansKR-Light";
  color: black;
  h5 {
    margin: 20px 0 10px 0;
  }
  h6 {
    margin: 10px 0 10px 20px;
  }
  p {
    margin: 10px;
  }
`;
