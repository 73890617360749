import React from "react";
import PointPurchasePage from "../../components/pointadd/point.purchase";
function AcconttransferContainer() {
  return (
    <div>
      <PointPurchasePage />
    </div>
  );
}

export default AcconttransferContainer;
