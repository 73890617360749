import React from "react";
import styled from "styled-components";

function Usagepolicy(props) {
  return (
    <UsageSection>
      <h3>비타비딩 구매자 이용약관</h3>
      <h5>제 1조 (목적)</h5>
      <p>
        본 약관은 비타비딩 (이하 "회사"라 함)가 제공하는 경매 플랫폼 서비스를
        이용하는 구매자(이하 "구매자" 또는 "낙찰자"라 함)와 회사 간의 권리,
        의무, 책임 사항 등을 규정함을 목적으로 합니다.
      </p>
      <h5>제 2조 (약관의 효력 및 변경)</h5>
      <p>
        1.이 약관은 구매자가 본 서비스를 이용하는 시점부터 효력을 발생합니다.
      </p>
      <p>
        2. 회사는 필요한 경우 관련 법령을 준수하며 이 약관을 변경할 수 있으며,
        변경된 약관은 회사의 웹사이트나 이메일 등의 방법으로 공지합니다. 변경된
        약관은 공지 후 구매자의 동의 여부에 따라 적용됩니다.
      </p>
      <h5>제 3조 (서비스의 제공)</h5>
      <p>
        1. 회사는 구매자에게 경매 플랫폼 서비스를 제공합니다. 서비스는 경매
        참여, 입찰, 낙찰, 거래 등을 포함합니다.
      </p>
      <p>
        2. 구매자는 회사가 제공하는 서비스를 이용함에 있어서 관련 법령과 약관에
        따라 성실하게 이용하여야 합니다.
      </p>
      <h5>제 4조 (구매자의 의무)</h5>
      <p>
        1. 구매자는 경매에 참여하기 위해 회사가 요청하는 정보를 정확하고
        최신으로 제공해야 합니다.
      </p>
      <p>
        2. 구매자는 경매 참여 시 공정한 방식으로 입찰하여야 합니다. 부정한
        수단을 사용하여 경매를 조작하거나 타인의 권리를 침해해서는 안 됩니다.
      </p>
      <p>
        3. 낙찰된 상품에 대한 구매자는 낙찰가에 따라 회사에 대금을 지불해야
        합니다. 대금 지불은 회사가 정한 기한 내에 완료되어야 합니다.
      </p>
      <p>
        4. 구매자는 구매한 상품에 대한 수령, 반품, 환불 등의 절차와 조건을
        회사의 정책에 따라 이행해야 합니다.
      </p>
      <h5>제 5조 (낙찰 및 거래)</h5>
      <p>
        1. 경매에서의 낙찰은 가장 높은 입찰가를 제시한 구매자에게 이루어집니다.
      </p>
      <p>
        2. 낙찰된 구매자와 회사는 상품 거래에 관한 계약을 체결합니다. 구매자는
        계약 내용에 따라 회사와 협조하여 거래를 진행해야 합니다.
      </p>
      <h5>제 6조 (수수료 및 결제)</h5>
      <p>
        1. 구매자는 회사가 정한 수수료를 부담해야 합니다. 수수료는 회사의 정책에
        따라 공지됩니다.
      </p>
      <p>
        2. 구매자는 낙찰한 상품에 대한 대금을 회사의 결제 방식에 따라 지불해야
        합니다.
      </p>
      <h5>제 7조 (책임의 한계)</h5>
      <p>
        1. 회사는 경매 플랫폼을 통해 판매되는 상품의 품질, 정확성, 법적 적합성
        등에 대해 어떠한 보증도 제공하지 않습니다.
      </p>
      <p>
        2. 구매자는 회사의 서비스 이용으로 인해 발생한 손해에 대해 회사에게
        일체의 책임을 묻지 않습니다.
      </p>
      <h5>제 8조 (관할 법원 및 준거법)</h5>
      <p>
        이 약관의 해석 및 회사와 구매자 간 발생한 분쟁에 대해서는 대한민국의
        관할 법원을 합의 관할 법원으로 합니다. 이 약관은 대한민국의 법령에 따라
        해석됩니다.
      </p>
    </UsageSection>
  );
}

export default Usagepolicy;
const UsageSection = styled.div`
  margin: 20px;
  padding: 10px;
  font-family: "NotoSansKR-Light";
  h5 {
    margin: 20px 0 10px 0;
  }
  h6 {
    margin: 10px 0 10px 20px;
  }
  p {
    margin: 10px;
  }
`;
